<template>
    <div class="notification-issuance">
        <div v-if="!isAdmin" class="top-tab">
            <van-tabs v-model="activeTab"  @click="clickTab">
                <van-tab title="重要程度" name="level">
                </van-tab>
                <van-tab title="时间" name="createTime">
                </van-tab>
            </van-tabs>
        </div>
        <div class="bottom-content">
            <van-search class="search-notify" v-model="listQuery.keyword" placeholder="请输入名称" shape="round" background="transparent"  @search="onSearch" input-align="center"/>
            <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    v-if="!isNoData"
                >
                    <van-swipe-cell v-for="(item, index) in list" :key="index">
                        <!-- <van-cell @click="seeDetails(item, index)">
                            <div class="content-item"><span>标题:</span><span class="title">{{item.title}}</span></div>
                            <div class="content-item"><span>内容:</span><span>{{item.context}}</span></div> 
                        </van-cell> -->
                        <div class="content-item">
                            <div class="time">上次发布时间：{{item.createTime}}</div>
                            <div class="content">
                                <div class="img">
                                    <img src="../../assets/images/news/img_news_finshed.png"/>
                                </div>
                                <div class="bottom">
                                    <p class="title" @click="seeDetails(item)">{{item.title}}</p>
                                    <p class="news-content">{{item.context}}</p>
                                </div>
                            </div>
                        </div>
                        <template #right>
                            <van-button square type="danger" text="删除" @click="deleteNotify(item.id)"/>
                        </template>
                    </van-swipe-cell>
                </van-list>
                <div class="no-data" v-else>
                    <div>
                        <img src="../../assets/images/icon_nodata.png"/>
                    </div>
                    <div>
                        <span class="no-data-text">暂无数据</span>
                    </div>
                </div>
            <!-- </van-pull-refresh> -->
            <router-link :to="{path: '/addNotificationIssuance'}" v-show="isAdmin">
                <div class="btn-add">+</div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { Form } from 'vant';
import {
    getUserType
} from '@/utils/cookie'
import {listPageNewsNotice, addNewsNotice, modifyNewsNotice, deleteNewsNotice} from '@/api/notificationIssuance';
export default {
    data() {
        return {
            isNoData: false,
            isAdmin: true,//判断是否是管理员
            refreshing: false,//是否刷新
            loading: false,//是否等待
            finished: false,//是否结束
            listQuery: {
                keyword: '',
                pageIndex: 1,
                pageSize: 200,
                type: 0,
                orderField: 'level', 
                isAuth: false,
                sort: 2
            },
            list: [],
            toolbars: {

            },
            activeTab: 'level',
        }
    },
    mounted() {
        this.isAdmin = getUserType() == 1 ? true : false;
        this.listPageNewsNotice();
    },
    methods: {
        /** 刷新 */
        onRefresh() {

        },
        /** 加载 */
        onLoad() {
            this.loading = false;
        },
        /** 查询列表 */
        listPageNewsNotice() {
            listPageNewsNotice(this.listQuery).then(res => {
                if (res.code == 200) {
                    //  if (!this.isAdmin) {
                    //     this.list = [
                    //         {title: '农事安排', context: '初春时节，地处智农农业产业园园区的员工抢抓农时，开展春耕备耕工作，回收秸秆、翻田犁地，一派繁忙景象。', createTime: '2021-03-01 14:00:00'},
                    //     ];
                    // } else {
                        this.list = res.data || [];
                        this.isNoData = this.list.length > 0 ? false : true;
                    // }
                }
               
            });
        },
        /** 删除 */
        deleteNotify(id) {
            deleteNewsNotice({id: id}).then(res => {
                if (res.code == 200) {
                    this.$notify({type: 'success', message: '删除成功'});
                    this.listPageNewsNotice();
                }
            });
        },
        /** 查看详情 */
        seeDetails(obj, index) {
            this.$router.push({name: 'addNotificationIssuance', query: obj})
        },
        /** 搜索 */
        onSearch() {
            this.listPageNewsNotice();
        },
        /** 切换排序字段 */
        clickTab(val) {
            this.listQuery.orderField = this.activeTab;
            this.listPageNewsNotice();
        },
    },
   /*  beforeRouteEnter(to, from,next) {
        next(vm => {
            if (from.name == 'addNotificationIssuance') {
                let obj = {
                    title: to.query.title || '',
                    content: to.query.content || ''
                }
                if (to.query.flag) {
                    vm.list[to.query.flag] = obj;
                } else {
                    vm.list.push(obj);
                }
            }
        });
    } */
}
</script>

<style lang="scss" scoped>
    .notification-issuance {
        position: absolute;
        top: 0;
        bottom: 50px;
        width: 357px;
        margin: 0 auto;
        // padding: 14px;
        // max-height: 600px;
        overflow: auto;
        .top-tab {

        }
        .bottom-content {
            width: 347px;
            margin: 0 auto;
            padding: 14px;
        }
        .btn-add {
            position: fixed;
            bottom: 70px;
            right: 10px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-size: 26px;
            color: #fff;
            text-align: center;
            line-height: 40px;
            background-color: #bfd9f6;
        }
        .left-label {
            width: 60px;
            margin: 8px;
            margin-left: 14px;
            font-size: 14px;
            color: #646566;
            text-align: left;
        }
        .content-item {
            margin-bottom: 12px;
            .time {
                margin-bottom: 10px;
                font-size: 12px;
                color: #A3A3A3;
            }
            .content {
                border-radius: 4px;
                background: #fff;
                .img {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    img {
                        width: 347px;
                        height: 124px;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                    }
                }
                .bottom {
                    padding: 14px;
                    .title {
                        width: 308px;
                        margin-bottom: 8px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 16px;
                        font-weight: 500;
                        color: #5A5A5A;
                    }
                    .news-content {
                        font-size: 14px;
                        color: #949494;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    .notification-issuance {
        .van-cell {
            width: 358px;
            margin: 0 auto;
            // margin-bottom: 10px;
            border-radius: 8px;
            .content-item {
                display: flex;
                span:nth-of-type(1) {
                    display: inline-block;
                    width: 40px;
                    margin-right: 4px;
                    text-align: right;
                }
                span:nth-of-type(2) {
                    display: inline-block;
                    flex: 1;
                }
                .title {
                    height: 30px;
                    font-size: 26px;
                }
            }
            .mavon-item {
                position: relative;
                .pop {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 9999;
                }
            }
        }
        .van-cell:nth-of-type(1) {
            margin-top: 10px;
        }
        .van-button--danger {
            height: 100%;
        }
        .v-note-wrapper {
            min-height: auto;
        }
        .v-note-wrapper .v-note-op {
            display: none;
        }
        .search-notify .van-cell {
            width: 346px;
            height: 32px;
            margin: 0 auto;
        }
        .van-tabs__wrap {
            padding-top: 10px;
            background-color: #fff;
        }
        .van-tabs__nav--line {
            .van-tab {
                border: 0;
            }
            .van-tabs__line {
                display: none;
            }
            .van-tab__text--ellipsis {
                font-size: 14px;
                color: #979797;
            }
            .van-tab--active .van-tab__text--ellipsis {
                color: #228EFF;
                background-color: transparent;
            }
            .van-tab--active {
                border-bottom: 2px solid #228EFF;
            }
        }
    }
</style>